export const gebTage = [
    {name: "", gebDate: ""},

];

export const vorstand = {
    ErsterVorstand: {
        Name: "Thomas Stadlmeier",
        Strasse: "Auf der Breiten 50",
        Ort: "92366 Hohenfels",
        Mail: "vorstand@fcb-hohenfels.de",
        Tel: "+49 151 12389744"
    },
    ErsterSchriftfuehrer: {
        Name: "Thomas Mulzer",
        Strasse: "Finkenweg 6",
        Ort: "92331 Lupburg",
        Mail: "mitgliedschaft@fcb-hohenfels.de",
        Tel: ""
    },
    Kartenbeauftragter: {
        Name: "Matthias Hofmann",
        Strasse: "",
        Ort: "",
        Mail: "karten@fcb-hohenfels.de",
        Tel: ""
    },
    Webadmin: {
        Name: "Rene Rendant",
        Strasse: "Sonnenstrasse 54",
        Ort: "92366 Hohenfels",
        Mail: "webadmin@fcb-hohenfels.de",
        Tel: "+49 160 96777103"
    }
};

export const verstorbeneMitglieder = [
    {
        name: "Heinz Söllner",
        description: "† 21.01.2024",
        src: "gedenken/24-01-21-Heinz-Soellner.jpg"

    },
    {
        name: "Erasmus Söllner",
        description: "† 29.08.2021",
        src: "gedenken/21-08-29-Erasmus-Soellner.jpg"

    },
    {
        name: "Otto Weigert",
        description: "† 07.09.2020",
        src: "gedenken/20-09-07-Otto-Weigert.jpg"
    },
    {
        name: "Thomas Jungkunz",
        description: "† 03.08.2019",
        src: "gedenken/19-08-03-Thomas-Jungkunz.jpg"
    },
    {
        name: "Josef Gassner",
        description: "† 21.05.2019",
        src: "gedenken/19-05-21-Josef-Gassner.jpg"
    },
    {
        name: "Gerda Söllner",
        description: "† 27.06.2015",
        src: "gedenken/15-06-27-Gerda-Söllner.jpg"
    },
    {
        name: "Wilhelm Weigert",
        description: "† 14.04.2014",
        src: "gedenken/14-04-14-Weigert-Willi.jpg"
    },
    {
        name: "Iris Jungkunz",
        description: "† 19.01.2014",
        src: "gedenken/14-01-19-Iris-Jungkunz.jpg"
    },
    {
        name: "Konrad Keil",
        description: "† 21.10.2012",
        src: "gedenken/12-10-21-Konrad-Keil.jpg"
    },
    {
        name: "Klaus Steuer",
        description: "12.10.2012",
        src: "gedenken/12-10-12-Klaus-Steuer.jpg"
    },
    {
        name: "Josef Liebl",
        description: "21.10.2009",
        src: "gedenken/09-10-21-Josef-Liebl.jpg"
    },
    {
        name: "Heinrich Hensel",
        description: "† 26.09.2009",
        src: "gedenken/08-09-26_hensel_heinrich.jpg"
    },
    {
        name: "Marianne Spangler",
        description: "† 24.12.2007",
        src: "gedenken/07-12-24_spangler_marianne.jpg"
    },
    {
        name: "Detlef Klemm",
        description: "† 05.12.2007",
        src: "gedenken/07-12-05_klemm_detlef.jpg"
    },
    {
        name: "Peter Brinkmann",
        description: "† 09.12.2006",
        src: "gedenken/06-12-09-Brinkmann-Peter.jpg"
    },
    {
        name: "Franz Koller",
        description: "† 09.08.2005",
        src: "gedenken/05-08-09-Koller-Franz.jpg"
    },
    {
        name: "Adolf Witka",
        description: "† 15.10.2004",
        src: "gedenken/04-10-15-Witka-Adolf.jpg"
    },
    {
        name: "Karl Spandl",
        description: "† 07.05.2003",
        src: "gedenken/03-05-07-Spandl-Karl.jpg"
    }
];

export const bilder = [
    {

        date: "2024-09-07",
        title: "Sommergrillfest 2024",
        directory: "2024_09_07_Sommergrillen_2024",
        description: ""
    },
    {

        date: "2024-03-02",
        title: "Starkbierfest 2024",
        directory: "2024_03_02_starkbierfest",
        description: ""
    },
    {

        date: "2023-11-25",
        title: "Weihnachtsfeier 2023",
        directory: "2023_11_25_Weihnachtsfeier",
        description: ""
    },
    {

        date: "2023-09-02",
        title: "Sommergrillfest",
        directory: "2023_09_02_Grillfest_2023",
        description: ""
    },
    {

        date: "2023-07-22",
        title: "300 Jahre St. Ulrich Kirche in Hohenfels - Lichtermesse",
        directory: "2023_07_22_300Jahre_StUlrich/Lichtermesse",
        description: ""
    },
    {

        date: "2023-05-27",
        title: "DJK SV - Elfmeter Tunier",
        directory: "2023_05_27_DJK_SV",
        description: ""
    },
    {

        date: "2023-04-21",
        title: "Jahreshauptversammlung 2023",
        directory: "2023_04_21_JHV2023",
        description: ""
    },
    {

        date: "2023-04-04",
        title: "DFB Pokal Spiel",
        directory: "2023_04_04_DFB_POKAL",
        description: ""
    },
    {

        date: "2022-10-08",
        title: "Stadionbesuch BVB Borussia Dortmund ./. FC Bayern München",
        directory: "2022_10_08_BVB_FCB",
        description: ""
    },
    {

        date: "2022-10-01",
        title: "Jahreshauptversammlung 2022 mit Grillfest",
        directory: "2022_10_01_JHV2022",
        description: ""
    },
    {
        date: "2022-09-13",
        title: "Champoins League FC Bayern München ./. Barcelona",
        directory: "2022_09_13_FCB_Barcelona_CL",
        description: ""
    },
    {
        date: "2022-09-10",
        title: "FC Bayern München ./. VfB Stuttgart",
        directory: "2022_09_10_FCB_vs_VfbStuttgart",
        description: ""
    },
    {
        date: "2022-07-23",
        title: "Ehrenabend - 20 Jahre Fanclub Forellenbachtal",
        directory: "2022_07_23_Ehrenabend",
        description: ""
    },
    {
        date: "2022-07-09",
        title: "Gemeindetunier der Stockschützen",
        directory: "2022_07_09_Stockschuetzen",
        description: ""
    },
    {
        date: "2022-07-02",
        title: "Elfmeter-Tunier mit dem 1.FCN Fanclub und dem FC Bayern Fanclub Kallmünz",
        directory: "2022_07_02_Elfmeterschiessen",
        description: ""
    },
    {
        date: "2022-06-25",
        title: "Hochzeit Thomas & Jasmin Stadlmeier",
        directory: "2022_06_25_Hochzeit_Stadlmeier",
        description: ""
    },

    {
        date: "2021-10-23",
        title: "Arbeitseinsatz am Container",
        directory: "2021_10_23_Arbeitseinsatz_am_Container",
        description: ""
    },
    {
        date: "2021-09-25",
        title: "Jahreshauptversammlung mit Grillfest",
        directory: "2021_21_09_25_JHV_Grillfest",
        description: ""
    },
    {
        date: "2019-09-07",
        title: "Fahrt ins Blaue",
        directory: "2019_19_09_07_Fahrt_ins_Blaue",
        description: ""
    },
    {
        date: "2019-05-11",
        title: "Hochzeit Juliane & Bernhard Spangler",
        directory: "2019_19_05_11_Hochzeit_Juliane_Bernhard_Spangler",
        description: ""
    },
    {
        date: "2002-04-15",
        title: "Gründungsversammlung am 15.04.2002",
        directory: "2002_04_15_Gruendungsversammlung",
        description: ""
    },
    {
        date: "2002-08-05",
        title: "Fackelwanderung",
        directory: "2002_08_05_Fackelwanderung",
        description: ""
    },
    {
        date: "2002-07-13",
        title: "Sommernachtsfest",
        directory: "2002_07_13_Sommernachtsfest",
        description: ""
    },
    {
        date: "2002-05-04",
        title: "FC Bayern München - FC Hansa Rostock",
        directory: "2002_05_04_FC_Bayern_Muenchen_FC_Hansa_Rostock",
        description: ""
    },
    {
        date: "2018-12-01",
        title: "Weihnachtsfeier",
        directory: "2018_12_01_Weihnachtsfeier",
        description: ""
    },
    {
        date: "2018-07-24",
        title: "Stockturnier",
        directory: "2018_07_24_Stockturnier",
        description: ""
    },
    {
        date: "2003-02-15",
        title: "Faschingsball",
        directory: "2003_02_15_Faschingsball",
        description: ""
    },
    {
        date: "2003-06-07",
        title: "Sommernachtsfest",
        directory: "2003_06_07_Sommernachtsfest",
        description: ""
    },
    {
        date: "2003-10-25",
        title: "Weinfahrt",
        directory: "2003_10_25_Weinfahrt",
        description: ""
    },
    // {
    //     date: "2017_17-02-04 ",
    //     title: "Faschingsball",
    //     directory: "2017_02_04_Faschingsball",
    //     description: ""
    // }

];

